import { lazy } from 'react';

const routers = [
  {
    path: '/login',
    component: lazy(() => import('../pages/Login'))
  },
  {
    path: '/googlelogin',
    component: lazy(() => import('../pages/Login/GoogleLogin'))
  },
  {
    path: '/signup',
    component: lazy(() => import('../pages/signUp'))
  },
  {
    path: '/resetPassword',
    component: lazy(() => import('../pages/resetPassword'))
  },
  {
    path: '/profile',
    title: 'profile',
    component: lazy(() => import('../pages/UserDetails'))
  },
  {
    path: '/panels',
    title: 'panels',
    component: lazy(() => import('../pages/Panels'))
  },
  {
    path: '/notificationsetting',
    title: 'notificationsetting',
    component: lazy(() => import('../pages/NotificationSetting'))
  },
  {
    path: '/postdetail/:postId',
    title: 'postdetail',
    component: lazy(() => import('../pages/HomeFeedPost'))
  },

  // __________________________________________
  {
    path: '/admin',
    component: lazy(() => import('../pages/admin/Index')),
    children: [
      {
        path: '/admin/home',
        title: 'homes',

        component: lazy(() => import('../pages/Panels/index'))
      },

      {
        path: '/admin/form',
        title: 'from',
        component: lazy(() => import('../pages/admin/Form'))
      },
      {
        path: '/admin/newRegisterList',
        title: 'NewRegisterList',
        component: lazy(() => import('../pages/admin/List/NewRegisterList'))
      },
      {
        path: '/admin/users',
        title: 'Others',
        component: lazy(() => import('../pages/admin/List/Others'))
      },
      {
        path: '/admin/postManagement',
        title: 'Management',
        component: lazy(() => import('../pages/admin/List/PostManagement'))
      }
      // 添加404页面配置
      //   {
      //     path: "*", // 匹配任何未匹配的路径
      //     title: "Not Found", // 404页面的标题
      //     component: lazy(() => import("../pages/NotFound")), // 404页面组件
      //   },
    ]
  },
  // __________________________________________

  {
    path: '/',
    component: lazy(() => import('../pages/Index')),
    children: []
  }
];
export default routers;
